import { FormattedMessage } from 'react-intl';
import { HelpCircleFilledIcon } from '@pledge-earth/product-language';

import type { Props as ErrorBaseProps } from '../Base/ErrorBase';
import { ErrorBase } from '../Base/ErrorBase';

export function Error404(props: Omit<ErrorBaseProps, 'icon' | 'title' | 'description'>) {
  return (
    <ErrorBase
      image={<HelpCircleFilledIcon />}
      title={<FormattedMessage id="auth.404.title" />}
      description={<FormattedMessage id="auth.404" />}
      {...props}
    />
  );
}
